import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo";
import EventFeed from "../components/events/event-feed";
import { graphql } from 'gatsby'

const EventsPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiEventos.edges[0].node;
    let title = page.seo.title;
    let metaDescription = page.seo.meta_description;

    if (pageContext.currentPage > 1) {
        title = title.replace('-', lang === 'es' ? `-  Página ${pageContext.currentPage} -` : `-  Page ${pageContext.currentPage} -`);
        metaDescription += lang === 'es' ? `-  Página ${pageContext.currentPage} -` : `-  Page ${pageContext.currentPage} -`;
    }

    return (
        <Layout pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={title} description={metaDescription}/>

            {/** EVENTOS
             ================================================== */}
            {data.allStrapiEvent.edges[0].node.lead &&
            <section data-jarallax="" data-speed=".8" className="pt-12 pb-10 pt-md-15 pb-md-14"
                     style={{
                         backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4),rgba(20, 0, 0, 0.15), rgba(20, 0, 0, 0.15)), url('" + require('../images/evento_01.jpg').default + "')",
                         backgroundPosition: "center",
                         backgroundSize: "cover"
                     }}>
                <div className="container">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-12 col-lg-12">

                                {/** Heading */}
                                <h1 className="display-2 font-weight-black text-white spacing text-center">
                                    {data.allStrapiEvent.edges[0].node.title}
                                </h1>

                                {/** Text */}
                                <h3 className="font-size-xl text-white mb-6 font-weight-bold spacing text-center">
                                    {data.allStrapiEvent.edges[0].node.description}
                                </h3>

                                {/** Button */}
                                <div align="center">
                                    <a href={data.allStrapiEvent.edges[0].node.link} target="_blank" rel="noreferrer" className="btn btn-primary spacing">
                                        {page.button}
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/** / .row */}
                    </div>
                </div>
                {/** / .container */}
            </section>}

            <EventFeed data={data} pageContext={pageContext}/>

        </Layout>
    )
};

export const eventsPagequery = graphql`
query eventsQuery($limit: Int!, $skip: Int!, $lang: String!, $dateFormat: String!){
    allStrapiEventos (
            filter: { locale: { eq: $lang } }
    ) {
        edges {
            node {
                id
                seo {
                    title
                    meta_description
                }
                title
                button
            }
        }  
    }
    allStrapiEvent(
        sort: {fields: [lead, date], order: [DESC, DESC]},
        limit: $limit,
        skip: $skip,
        filter: { locale: { eq: $lang } }
    ) {
        edges {
            node {
                id
                lead
                title
                description
                link
                emplacement
                date(formatString: $dateFormat, locale: $lang)
            }
        }
    }
    cookies: markdownRemark(
        frontmatter: {
            lang: { eq: $lang }
            name: { eq: "cookies" }
        }
    ) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}
`;

export default EventsPage;
