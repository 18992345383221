import React from "react"
import EventPost from "./event-post";
import Pagination from "../common/pagination";

const EventFeed = ({data, pageContext}) => (
    <section className="position-relative py-8 py-md-11 mb-9">

        {/** Content */}
        <div className="container">
            <div className="row align-items-center">

                {data.allStrapiEvent.edges.map((document, i) => (
                    <EventPost event={document.node} key={i}/>
                ))}

                <Pagination pageContext={pageContext}/>

            </div>
            {/** / .row */}
        </div>
        {/** / .container */}

    </section>

)

export default EventFeed
