import React from 'react'
import {Link} from "gatsby";

const Pagination = ({pageContext}) => {

    const {currentPage, numPages, pagePath} = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/" + pagePath : (currentPage - 1).toString()
    const nextPage = "/" + pagePath + "/" +(currentPage + 1).toString()

    return (
        <div className="col-12 col-xl-12 d-flex">
            <ul className="pagination">

                {!isFirst &&
                <li className="page-item"><a
                    className="page-link text-blue spacing font-weight-bold" href={prevPage}>&laquo;</a>
                </li>}

                {Array.from({ length: numPages }, (_, i) => (
                    <li className="page-item" key={i + 1}>
                        <Link className="page-link text-blue spacing font-weight-bold"
                              key={`pagination-number${i + 1}`} to={`/` + pagePath + `/${i === 0 ? "" : i + 1}`}>
                            {i + 1}
                        </Link>
                    </li>
                ))}

                {!isLast &&
                <li className="page-item"><a
                    className="page-link text-blue spacing font-weight-bold" href={nextPage}>&raquo;</a>
                </li>}
            </ul>
        </div>
    )

}

export default Pagination