import React from "react"

const EventPost = ({event}) => (
    
    <div className="col-lg-4">
        {/** Only Card*/}
        <div className="card card-row border-card card-evento mb-6">
            <div className="card-header">

                <div className="card-meta event">
                    <h6 className="text-uppercase text-muted mb-0">
                        <i className="fe fe-calendar color-tecalis"/>{event.date}
                    </h6>
                    {/** Locate*/}
                    <h6 className="text-uppercase text-muted mb-0 ml-auto">
                        <i className="fe fe-map-pin color-tecalis"/> {event.emplacement}
                    </h6>
                </div>

            </div>
            <a className="card-body my-auto" href={event.link}  target="_blank" rel="noreferrer" >
                <h2 className="font-weight-bold spacing">
                    {event.title}
                </h2>
                <p className="mb-0 text-muted spacing">
                    {event.description}
                </p>
            </a>

        </div>
        {/** ./ card*/}

    </div>
)

export default EventPost
